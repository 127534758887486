// import { DataAnalyticsService } from './../../services/data-analytics.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject, OnDestroy, EventEmitter, Output, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { SelectLineComponent } from '../select-line/select-line.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-factory-selection-modal',
  templateUrl: './factory-selection-modal.component.html',
  styleUrls: ['./factory-selection-modal.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class FactorySelectionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() dateSelected: EventEmitter<any> = new EventEmitter<Date>();
  date = new FormControl(moment());
  factorySelectionForm: FormGroup;
  clusterList: any[] = [];
  factoryList: any[] = [];
  fileredClusterList: any[] = [];
  filteredFactoryList: any[] = [];
  isSelected: boolean;
  subscriptionMessage: string;
  uniqueClusterList: any;
  applicationList: any = [];
  plannedYr: any;
  lineList: any[] = [];
  filteredLineList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataPassed,
    public fb: FormBuilder,
    public dialog: MatDialogRef<FactorySelectionModalComponent>, private cdr: ChangeDetectorRef, public dialog1: MatDialog
    // private daService: DataAnalyticsService
  ) { }

  ngOnInit() {
    this.factorySelectionForm = this.fb.group({
      cluster: [null, [Validators.required]],
      factory: [null, [Validators.required]],
      application: [null, [Validators.required]],
      date: [null, [Validators.required]],
      line: [""]
    });

    this.factorySelectionForm.get('application').valueChanges.subscribe(value => {
      this.updateConditionalFieldValidators(value);
    });
    this.f.date.setValue(this.date.value.year());
    sessionStorage.setItem('selectedDAAYear', JSON.stringify(this.date.value.year()));
    // this.f.date = this.date.yea;
    this.f.factory.disable();
    this.f.cluster.disable();
    this.f.line.disable();
    this.clearSelected();
    // this.getClusters();
    this.applicationList = [{ name: "BuildApp", value: "buildApp" }, { name: "DFOS", value: "dfos" }, { name: "SPC", value: "SPC" }, { name: "NGTW", value: "ngtw" }, { name: "MCOF", value: "mcof" }];
  }

  updateConditionalFieldValidators(value: string) {
    const lineField = this.factorySelectionForm.get('line');
    if (value === 'DFOS' || value === 'SPC' || value === 'MCOF') {
      lineField.setValidators([Validators.required]);
    } else {
      lineField.clearValidators();
    }
    lineField.updateValueAndValidity();
  }




  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onDateSelected(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    dp.close();
    let isoString = new Date(this.date.value);
    const date = moment(isoString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    this.date.setValue(date);
    this.dateSelected.emit(date);
    this.f.date.setValue(ctrlValue.year());
    sessionStorage.setItem('selectedDAAYear', JSON.stringify(ctrlValue.year()));
    this.isSelected = true;
    this.subscriptionMessage = '';
  }





  get f() {
    return this.factorySelectionForm.controls;
  }
  /* istanbul ignore next */
  autoCompleteCluster(event: string) {
    this.f.factory.setValue('');
    this.f.factory.disable();
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      // const clonedClusterList = [...this.clusterList];
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.uniqueClusterList = this.uniqueClusterList.filter(c => {
        if (c.name.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
    } else {
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.filteredFactoryList = this.factoryList;
      this.isSelected = false;
    }
  }

  autoCompleteFactory(event: string) {
    this.isSelected = false;
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      const clonedFactoryList = [...this.factoryList];
      this.filteredFactoryList = clonedFactoryList.filter(f => {
        if (f.name.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
    } else {
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.filteredFactoryList = this.factoryList;
    }
  }

  onAppChange(factory: any) {
    this.f.application.setValue(factory.name);
    sessionStorage.setItem('selectedDAApplication', JSON.stringify(factory));
    this.isSelected = true;
    this.subscriptionMessage = '';
    this.getClusters();
    this.f.cluster.enable();
  }


  getClusters() {
    const selectedApplication = JSON.parse(sessionStorage.getItem('selectedDAApplication'));
    const clusters = selectedApplication.value === "buildApp" || selectedApplication.value === "ngtw" ? JSON.parse(sessionStorage.getItem('userfactory.data')) : JSON.parse(sessionStorage.getItem('dfos.userfactory.dataforline'));
    if (clusters) {
      this.clusterList = this.fileredClusterList = clusters;
      this.uniqueClusterList = [];
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      if (clusters.length === 1) {
        this.onClusterChange(clusters[0]);
      }
    }
  }
  /* istanbul ignore next */
  getFactories(cluster: any) {
    this.factoryList = this.filteredFactoryList = [];
    for (let index = 0; index < this.fileredClusterList.length; index++) {
      if (this.fileredClusterList[index].name === cluster.name) {
        this.fileredClusterList.forEach((entry) => {
          entry.factories.forEach(element => {
            element['subRegionName'] = entry.name;
          });
        });

        this.factoryList = this.filteredFactoryList = [...this.factoryList, ...this.fileredClusterList[index].factories];
      }

    }
    this.factoryList.sort((a, b) => a.name.localeCompare(b.name));
  }


  getLineList(factories: any) {
    this.lineList = this.filteredLineList = [];
    for (let index = 0; index < this.filteredFactoryList.length; index++) {
      if (this.filteredFactoryList[index].name === factories.name) {
        this.filteredFactoryList.forEach((entry) => {
          entry.lineList.forEach(element => {
            element['name'] = element.lineName;
          });
        });

        this.lineList = this.filteredLineList = [...this.lineList, ...this.filteredFactoryList[index].lineList];
      }

    }
    this.lineList.sort((a, b) => a.name.localeCompare(b.name));
  }



  clearSelected() {
    sessionStorage.removeItem('selectedDACluster');
    sessionStorage.removeItem('selectedDAFactory');
    sessionStorage.removeItem('selectedDAApplication');
    sessionStorage.removeItem('selectedDAADate');
    sessionStorage.removeItem('selectedDAFactoryLine');
  }

  onClusterChange(cluster: any) {
    this.f.cluster.setValue(cluster.name);
    sessionStorage.setItem('selectedDACluster', JSON.stringify(cluster));
    this.f.factory.setValue('');
    this.f.factory.enable();
    this.getFactories(cluster);
  }

  onFactoryChange(factory: any) {
    this.f.factory.setValue(factory.name);
    sessionStorage.setItem('selectedDAFactory', JSON.stringify(factory));
    this.isSelected = true;
    this.subscriptionMessage = '';
    this.f.line.setValue('');
    this.f.line.enable();
    if (this.f.application.value === 'DFOS' || 'SPC' || 'MCOF') {
      this.getLineList(factory);
    }

  }

  onLineChanged(line: any) {
    this.f.line.setValue(line.name);
    sessionStorage.setItem('selectedDAFactoryLine', JSON.stringify(line));
  }


  getLocalStorageData(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  onSubmit() {
    if (this.factorySelectionForm.valid) {
      sessionStorage.setItem('selectedDACluster', JSON.stringify(this.filteredFactoryList[0]));
      this.dialog.close(
        {
          action: 'submitted',
          selectedData: this.factorySelectionForm.value,
          selectedClusterId: this.filteredFactoryList[0].name
        }
      );
    }
  }

  createLine() {
    this.dialogClose();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';

    const dialogRef = this.dialog1.open(SelectLineComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res: { action: string, selectedData: any }) => {
      console.log('res1111', res);
      if (res.action === "cancelled") {
        // this.setFactorySummary();
        // console.log('res', res);
        this.getClusters();
      }
    });
  }

  dialogClose() {
    this.dialog.close({ action: 'cancelled' });
  }


  ngOnDestroy(): void {

  }

}
